<div class="container">
    <button class="btn btn-success" (click)="exportexcel() ">Export to Excel</button>

    <table id="excel-table" class="table">
        <tr>
            <th>Id</th>
            <th>Full Name</th>
            <th>Code</th>
            <th>Email</th>
        </tr>
        <tr *ngFor="let item of listOfData">
            <td>{{item.id}}</td>
            <td>{{item.FullName}}</td>
            <td>{{item.Code}}</td>
            <td>{{item.email}}</td>
        </tr>
    </table>
</div>