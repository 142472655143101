<p>export-excel-file-custom works!</p>

<div class="container">
    <script async="" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5125544300826919" crossorigin="anonymous"></script>
    <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-5125544300826919" data-ad-slot="5155152706"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
</div>

<div class="container">
    <div class="row">
        <img src="https://i0.wp.com/codelife.javelupango.com/wp-content/uploads/2022/04/image-21.png?resize=349%2C90&ssl=1">
        <button class="btn btn-success" (click)="exportexcel()">Export Excel</button>
    </div> <br>
    <div class="row">
        <img src="https://codelife.javelupango.com/wp-content/uploads/2022/04/image-22.png">
        <button class="btn btn-success" (click)="exportexcel01()">Export Excel</button>
    </div>
    <div class="row">
        <img src="https://i0.wp.com/codelife.javelupango.com/wp-content/uploads/2022/04/image-23.png?resize=433%2C85&ssl=1">
        <button class="btn btn-success" (click)="exportexcel02()">Export Excel</button>
    </div>
    <div class="row">
        <img src="https://i0.wp.com/codelife.javelupango.com/wp-content/uploads/2022/04/image-24.png?resize=502%2C76&ssl=1">
        <button class="btn btn-success" (click)="exportexcel03()">Export Excel</button>
    </div>
    <div class="row">
        <img src="https://i0.wp.com/codelife.javelupango.com/wp-content/uploads/2022/04/image-25.png?resize=442%2C91&ssl=1">
        <button class="btn btn-success" (click)="exportexcel04()">Export Excel</button>
    </div>
    <div class="row">
        <img src="https://i0.wp.com/codelife.javelupango.com/wp-content/uploads/2022/04/image-26.png?resize=442%2C91&ssl=1">
        <button class="btn btn-success" (click)="exportexcel05()">Export Excel</button>
    </div>
</div>

<div class="container">
    <script async="" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5125544300826919" crossorigin="anonymous"></script>
    <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-5125544300826919" data-ad-slot="5155152706"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
</div>